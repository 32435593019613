<template>
    <div class="main-wrap">
        <div class="jobsheet-back" @click="goback()">
            {{ backName }}
        </div>
        <div class="jobsheet-title">
            <div class="jobsheet-right">
                <div class="jobsheet-right-item">
                    <span class="jobsheet-right-name">{{ orderInfo.lotno }}</span>
                    <span class="jobsheet-right-status">{{ getpreProofingName(orderInfo.proofCategory) }}</span>
                </div>
                <div class="jobsheet-right-item">
                    <span class="jobsheet-right-item-label">报单人</span>：
                    <span>{{ orderInfo.creater }}</span>
                </div>
                <div class="jobsheet-right-item">
                    <span class="jobsheet-right-item-label">报单时间</span>：
                    <span>{{ getformat(orderInfo.createTime) }}</span>
                </div>

            </div>
        </div>

        <div>
            <!-- <Time-line :processData="processData"></Time-line> -->
            <div class="timeLine">
                <div style="    " class="timeLine-before-item">
                    <div v-for="(item, index) in processData" :key="index" class="timeLine-item">
                        <div class="timeLine-left">

                            <div class="icon icon_2" v-if="index == 0">
                                <!-- <u-icon name="checkbox-mark" color="#fff" :size="24"></u-icon> -->
                            </div>
                            <div class="icon icon_0" v-else></div>

                        </div>
                        <div class="timeLine-right">
                            <div>
                                <div class="process-header">
                                    <div class="process-header__name">{{ item.proofingStatusDesc }}</div>
                                    <!-- :style="{ color: getColor(item.proofingStatus) }" -->
                                    <!-- <div >{{ item.proofingStatusDesc }}</div> -->
                                </div>
                                <div class="process-desc">
                                    <div v-if="item.proofingStatus == '4'">
                                        <div class="process-desc__text">{{ `开始时间：${getformat(item.correspondingTime)}` }}
                                        </div>
                                       
                                    </div>
                                    <div v-else-if="item.proofingStatus == '1'">
                                        <div class="process-desc__text">{{ `报单时间 ${getformat(item.correspondingTime)}` }}</div>
                                    </div>
                                    <div v-else-if="item.proofingStatus == '2'">
                                        <div class="process-desc__text">{{ `审核时间：${getformat(item.correspondingTime)}` }}</div>
                                    </div>
                                    <div v-else-if="item.proofingStatus == '3'">
                                        <div class="process-desc__text">{{ `分配完成：${getformat(item.correspondingTime)}` }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>

<script >

import moment from "moment";




export default {
    name: "proofItemColor",
    props: {
        srcId: {
            type: Number,

        },
        colorId: {
            type: Number,

        },
        version: {
            type: String,
            default() {
                return '';
            }
        },
        orderInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            processData: [],
            // orderInfo: {},
            backName: '返回',
            jobsheetNameData: {}
        }
    },
    methods: {
        getformat(date) {
            return date == '' || date == '-' || date == null ? '-' : moment(date).format('YYYY-MM-DD HH:mm');
        },
        getColorStatusName(val) {
            switch (val) {
                case "1":
                    return "报单成功";
                case '2':
                    return "化验室审核通过";
                case "3":
                    return "任务已分配";
                case "4":
                    return "打样中";
                case "5":
                    return "配方已完成";
                case "6":
                    return "已取消";
                default:
                    return val;
            }
        },
        getpreProofingName(val) {
            switch (val) {
                case "1":
                    return "ABC样";
                case "2":
                    return "自由色";
                case "3":
                    return "手工样";
                case "4":
                    return "做米样";
                case "5":
                    return "复样开配方";
                case "6":
                    return "打准开配方";
                case "7":
                    return "确认样";
            }
        },


        async getProofingData(srcId, colorId, version) {
            try {
                let res = await this.$Api.getShareProcess({
                    token: this.$AuthLogin.urlps(window.location.href).data("token"),
                    version: this.$AuthLogin.urlps(window.location.href).data("version"),
                    id: colorId
                });
                this.processData = res.data;
            } catch (error) {

            }

        },

        countChar(str, char) {
            const arr = str.split(char);
            return arr.length - 1;
        },
        showStatus(item) {
            if (item.targetJobSheetId.includes('G') || (item.targetJobSheetId.split('H').length - 1) >= 1) {
                if ((item.targetJobSheetId.split('H').length - 1) == 1 && item.targetJobSheetId[0] == 'H') {
                    return false;
                }
                return true;
            }
            else {
                return false;
            }
        },
        goback() {
            this.$parent.tabChildProofColor();
        }
    }






}
</script>

<style lang="less">
.main-wrap {
    width: 7.50rem;
    min-height: 100%;
    padding-bottom: 0.50rem;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: PingFangSC-Regular, PingFang SC;
    height: fit-content;
    .process-desc__text{
        background: #F7F8FA;
    }
    .jobsheet-back {
        text-align: left;
        width: 100%;
        padding-top: 0.10rem;
        padding-left: 0.25rem;
    }

    .jobsheet-title {
        display: flex;
        padding-top: 0.14rem;
        padding-bottom: 0.32rem;
        width: 100%;
        background-color: #ffffff;
        border-bottom: 0.16rem solid #F7F8FA;

        .jobsheet-left {
            margin-left: 0.32rem;
            width: 1.36rem;
            height: 1.36rem;
            border-radius: 0.08rem;
            margin-right: 0.30rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        .jobsheet-right {
            // flex: 1;
            font-size: 0.28rem;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 0.40rem;
            color: #86909C;
            padding-top: 0.26rem;
            padding-left: 0.36rem;
            text-align: left;

            .colorRed {
                color: #F95F5F;
            }

            .jobsheet-right-item {
                margin-bottom: 0.12rem;

            }

            .jobsheet-right-item-label {

                text-align: justify;
                text-align-last: justify;
                width: 1.20rem;
                display: inline-block;
            }

            .jobsheet-right-name {
                font-size: 0.36rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: bold;
                color: #1D2129;
                line-height: 0.50rem;
            }

            .jobsheet-right-status {
                background: #F3F5F9;
                border-radius: 0.04rem;
                font-size: 0.24rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4E5969;
                line-height: 0.40rem;
                padding: 0 0.12rem;
                margin-left: 0.16rem;
            }
        }
    }

    .jobsheet-list {
        padding: 0 0.32rem;
        width: 100%;
    }

    .timeLine {
        padding: 0.32rem 0.32rem 0.32rem 0.54rem;
        width: 7.5rem;
        box-sizing: border-box;
        background-color: #ffffff;
        // display: flex;
        .icon {
            width: 0.24rem;
            height: 0.24rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .icon_0 {
            border: 0.02rem solid rgba(22, 93, 255, 0.2);
            background: #C9CDD4;


        }

        .icon_1 {
            border: 0.02rem solid #3370ff;
            background: #3370ff;
            color: #ffffff;
        }

        .icon_2 {
            border: 0.02rem solid rgba(22, 93, 255, 0.2);
            ;
            background: #165DFF;

        }

        .process-header {
            display: flex;
            justify-content: space-between;
            font-size: 0.28rem;

            &__name {
                font-size: 0.32rem;
                color: #1D2129;
                font-weight: bold;

                font-family: PingFangSC-Medium, PingFang SC;

            }
        }

        .process-desc {
            margin-top: 0.16rem;
            width: 5.96rem;
            background: #F7F8FA;
            border-radius: 0.08rem;
            padding: 0.13rem 0.24rem;
            color: #86909C;
            font-size: 0.28rem;
            text-align: left;

            &__text {
                // height: 56rem;
                line-height: 0.48rem;
                color: #86909C;
            }
        }

        .timeLine-item {
            display: flex;
            flex-direction: row;
            flex-direction: column;
            width: 100%;
            position: relative;
            margin-bottom: 0.16rem;

            &::before {
                content: '';
                position: absolute;
                left: -0.68rem;
                top: 0.30rem;
                width: 0.01rem;
                bottom: -0.10rem;
                background-image: -webkit-linear-gradient(top, #979797 0%, #979797 0%, 0.05rem, transparent 0.05rem, transparent);
                background-image: linear-gradient(to bottom, #979797 0%, #979797 0%, 0.05rem, transparent 0.05rem, transparent);
                background-size: 100% 0.10rem;
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
                -webkit-transform: scaleX(0.5);
                transform: scaleX(0.5);
            }

            &:last-child::before {
                content: '';
                background-image: none;
            }
        }

        .timeLine-left {
            display: flex;
            position: absolute;
            align-items: center;

            justify-content: center;
            z-index: 1;
            font-size: 0.12rem;
            left: -0.80rem;
            top: 0.01rem;

        }

        .timeLine-right {
            flex: 1;
        }

        .timeLine-before-item {
            padding-left: 0.68rem;
        }
    }
}</style>
