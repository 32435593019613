<!--
 * @Author: meng xiang cheng 3257833780@qq.com
 * @Date: 2022-02-11 15:41:16
 * @LastEditors: meng xiang cheng 3257833780@qq.com
 * @LastEditTime: 2024-01-09 16:23:04
 * @FilePath: \s-share-h5\src\views\index\index.vue
 * @Description: 
-->
<template>
	<div class="index_page">
		<!-- <img src="../../assets/logo_bdl.png" alt="" style="display: none;"> -->
		<div class="content">
			<proofItem2 v-for="(item, index) in dataList" :item="item" :key="index" :shareToken="shareToken"
				v-if="shareType == 'preproof'" :version="version"></proofItem2>
			<reportItem2 v-for="(item, index1) in dataList" :item="item" :key="index1" :shareToken="shareToken"
				:version="version" v-else></reportItem2>
		</div>
		<div class="bottom_fixed">
			<span>前往APP查看所有订单</span>
			<button class="gotoApp" @click="gotoDownLoad">去下载</button>
		</div>
		<Pop type="tips" ref="tips_pop">
			<div slot="content" class="error_content">
				{{ error }}
			</div>
		</Pop>

		<Pop type="wx_down" ref="wx_down_pop">
			<div slot="content" class="wx_down_content">
				<img src="../../assets/icon_jiantou.png" class="openWithDefault" alt="使用默认浏览器打开">
				<div class="defaut_liulan">
					<img src="../../assets/defalut_liulan.png" class="liulan" alt="默认浏览器">
					<p class="text">使用默认浏览器打开</p>
				</div>
			</div>
		</Pop>

	</div>
</template>

<script>
import Pop from "../../components/pop/pop.vue";
import reportItem2 from "../../components/reportItem2/index.vue";
import proofItem from "../../components/proofItem/index.vue";
import timeFormat from "../../until/function/time.js";
import proofItem2 from "../../components/proofItem2/index.vue";
import ca from "../../js/common.js";
export default {
	data() {
		return {
			dataList: [],
			srcId: 0,
			targetorder: "",
			ispopShow: false,
			list: [],
			type: "color",
			selectColor: "",
			selectColorId: "",
			selectState: "",
			selectStateId: "",
			version: "",
			jobSheetData: [],
			// jobSheetData: [{"id":19645,"srcId":16961,"targetColorId":"DM221130073","targetWhiteGreyId":null,"colorNum":"BF测试","orderRolls":1,"prodQtyYards":0,"cardQty":1,"remarks":"测试","targetRecipeStatus":null,"targetUnOpenedJobSheetCnt":null,"headCylinderConfirm":null,"sampleConfirm":null,"headCylinderStatus":null,"sampleStatus":null,"dyeType":"活","proofing":"copy","depth":"浅","invoice":true}],
			colorList: [],
			error: "该分享链接无效",
			shareToken: "",
			shareType: "",
			isStateActive: false,
			isColorActive: false,

			preFlowList: [{
				value: "",
				label: "全部状态"
			},
			{
				value: "0",
				label: "未开始"
			},
			{
				value: "1",
				label: '进行中'
			},
			{
				value: "2",
				label: "已完成"
			}
			],
			flowList: [{
				value: "",
				label: "全部状态"
			},
			{
				value: '1',
				label: "进行中"
			},
			{
				value: '2',
				label: '完成'
			},
			{
				value: '3',
				label: "终止"
			}
			],

			jobkindList: ['正常转卡', '试样工卡', '厂外重修', '厂外改染', '厂内重修', '厂内改染', '并卡', '拆卡', '外修头开卡']
		}
	},
	components: {
		Pop,
		reportItem2,
		proofItem,
		proofItem2
	},
	filters: {
		getCurProcessCompleteTime(data) {
			if (data) {
				return timeFormat(data, "mm-dd")
			}
			return "-";
		},
		getPreCurProcessCompleteTime(data) {
			if (data) {
				return timeFormat(data, "yyyy-mm-dd hh:MM")
			}
			return "-";
		}
	},
	methods: {
		// 下载app
		gotoDownLoad() {
			this.$router.push({
				path: "/dashboard"
			})
			return;
			if (ca.fn.isWeiXin()) {
				this.$refs.wx_down_pop.openPop();
				return
			}
			if (!!ca.browser.versions.ios) {
				// console.log("iso")
				this.ios();
				// window.timeout = setTimeout(function() {
				// window.location = "https://itunes.apple.com/cn/app/id1599196561"; //ios下载地址  
				// }, 3000);
				// window.location = "bdlShchemes://";
			} else {
				this.android();
				// try {
				// 	window.location = 'bdlShchemes://';
				// 	setTimeout(function() {
				// 		window.location = ""; //android下载地址  
				// 	}, 500);
				// } catch (e) {}
			}

		},
		android() {
			// var _clickTime = new Date().getTime();
			// window.location.href = 'bdlShchemes://'; /***打开app的协议，有安卓同事提供***/

			// //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
			// var _count = 0,
			// 	intHandle;
			// intHandle = setInterval(function() {
			// 	_count++;
			// 	var elsTime = new Date().getTime() - _clickTime;
			// 	if (_count >= 100 || elsTime > 3000) {
			// 		clearInterval(intHandle);
			// 		//检查app是否打开
			// 		if (document.hidden || document.webkitHidden) {
			// 			// 打开了
			// 			window.close();
			// 		} else {
			window.location.href = "http://test.wwitechs.cn/download/budala/bdl_20220214.apk"; //下载链接
			// 		}
			// 	}
			// }, 20);

		},
		ios() {
			var _clickTime = +(new Date());
			// var ifr = document.createElement("iframe");
			// ifr.src = "bdlShchemes://"; /***打开app的协议，有ios同事提供***/
			// // ifr.style.display = "none";
			// document.body.appendChild(ifr);
			//启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
			var _count = 0,
				intHandle;
			intHandle = setInterval(function () {
				_count++;
				var elsTime = +(new Date()) - _clickTime;
				if (_count >= 100 || elsTime > 3000) {
					clearInterval(intHandle);
					// document.body.removeChild(ifr);
					//检查app是否打开
					if (document.hidden || document.webkitHidden) {
						// 打开了
						window.close();
					} else {
						window.location.href = "https://itunes.apple.com/cn/app/id1599196561"; //下载链接
					}
				}
			}, 20);
		},

		closeSelectPop() {
			this.ispopShow = false;
		},
		changeObjSheetOpen(index1) {
			this.jobSheetData.forEach((item, ins) => {
				if (ins == index1) {
					item.isopen = !item.isopen
				}
			})
		},
		showSelect(type) {
			console.log("showSelect", type)
			if (this.type == type || !this.dropShow) {
				this.ispopShow = !this.ispopShow;
			}

			this.type = type;
			if (type == "state") {
				this.list = this.flowList;
				this.isStateActive = true
				this.isColorActive = false
			} else if (type == "color") {
				this.list = this.colorList;
				this.isStateActive = false
				this.isColorActive = true
			}
		},
		async getReportSheetList(initView) {
			try {
				const res = await this.$Api.getShareOrder({
					id: this.targetorder,
					version: this.version,
					token:this.shareToken
				})
				this.dataList = [res.data];
			} catch (error) {

			}
			// const res = await this.$Api.getSalesmanListV2({
			// 	shareToken: this.shareToken,
			// },this.version);
			// if (res.code != 0) return;
			// this.dataList = res.data;
		},



		// 预打样
		// async getPreproofSheetList(initView) {
		// 	try {
		// 		const res = await this.$Api.getShareOrder({
		// 			id: this.targetorder,
		// 			version: this.version,
		// 			token: "JBf99H5YTsdLDlZWRKOXRmpoX5iEPNhFV_3zZVkpueu6OaYE5rJtvIOnxdqrHNEasa6FU3ACXwpCrvkyAsfaKj6kZ_dVnYcY3NKxAoP6NbHXMIS_43RmdQXnIraiovqx"
		// 		})
		// 		this.dataList = [res.data];
		// 	} catch (error) {

		// 	}
		// 	// const res = await this.$Api.getProproofingV2({
		// 	// 	shareToken: this.shareToken
		// 	// }, this.version);
		// 	// if (res.code != 0) return;
		// 	// this.dataList = res.data;
		// 	// console.log(res, "res");
		// }
	},
	mounted() {
		let href = location.href;
		// let href = 'http://share.iclothtest.wwitechs.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmRlcklkIjozNjU3NiwiZXhwVGltZSI6MCwiZXhwIjoxNjgxODA4NDg0fQ.fD70_lipEzms_HQyD-YFRgSjsuOigYCvkyxrM3QY2_0&sharetype=preproof&version=1.6.1_beta#/'
		let urlsp = this.$AuthLogin.urlps(href);
		if (urlsp.data("token")) {
			this.version = urlsp.data("version");
			this.shareToken = urlsp.data("token");
			this.targetorder = urlsp.data("targetorder");
			let shareType = urlsp.data("sharetype");
			this.shareType = shareType;
			if (shareType && shareType == "preproof") {
				this.shareType = shareType;
				this.flowList = this.preFlowList;
				this.getReportSheetList(true);
			} else {

				this.getReportSheetList(true);
			}

		} else {
			this.$refs.tips_pop.openPop();

		}


	}
}
</script>
