<template>
	<div class="main-wrap">
		<div class="back" @click="goback()">
			{{ backName }}
		</div>
		<div class="color-title">
			<div class="color-left"
				:style="{ backgroundColor: ColorNameData.rgbVal && !ColorNameData.rgbVal.includes('#') ? '#' + ColorNameData.rgbVal : '' }">
				<img v-if="!(ColorNameData.rgbVal && !ColorNameData.rgbVal.includes('#'))"
					src="../../assets/color_default_big.svg" alt="">
			</div>
			<div class="color-right">
				<div class="color-right-item">
					<span class="color-right-name">{{ ColorNameData.bindNumber }}</span>
					<span class="color-right-status">{{ ColorNameData.category }}</span>
				</div>
				<div class="color-right-item">
					<span>颜色：</span>
					<span>{{ ColorNameData.colorNum }}</span>
				</div>
				<div class="color-right-item">
					<span>品名：</span>
					<span>{{ ColorNameData.productName }}</span>
				</div>
				<div>
					<span>排缸：</span>
					<span>共<span class="colorRed">{{ ColorNameData.orderVat }}</span>缸/已排<span class="colorRed">{{
						 ColorNameData.doingVat ? ColorNameData.doingVat : 0 }}</span>缸</span>
				</div>
			</div>
		</div>
		<div class="color-list">

			<div class="color-list-item" v-for="(item, index) in colorListData" :key="item.proofingStatus">
				<div class="color-list-item-title">
					<div class="color-list-item-title-left">
						<span class="color-list-item-title-left-color"
							:style="{ backgroundColor: ColorNameData.rgbVal && !ColorNameData.rgbVal.includes('#') ? '#' + ColorNameData.rgbVal : '' }">
							<img v-if="!(ColorNameData.rgbVal && !ColorNameData.rgbVal.includes('#'))"
								src="../../assets/icon_default_color.svg" alt="">
						</span>
						<span class="color-list-item-title-left-title">{{ item.dyeEnterpriseJobSheetId }}</span>
						<!-- <span class="color-list-item-title-left-status" v-if="showStatus(item)">{{ item | dealStatus
						}}</span> -->
					</div>
					<span class="color-list-item-title-right">
						<span class="color-list-item-title-right-span">{{ item.stationName }}</span>
					</span>
				</div>
				<!-- <div class="color-list-item-title-content">
					<div class="color-list-item-title-content-item">
						<span class="color-list-item-title-content-item-value">{{ getKunCun(item, 'blankRoll', 'blankMeter')
						}}</span>
						<span class="color-list-item-title-content-item-label" style="color: #1D2129;">{{ item |
							getcurProcessCompleteTime }}</span>
					</div>
				</div> -->
				<div  class="color-list-item-title-content">
					<div class="color-list-item-title-content-item">
						<span class="color-list-item-title-content-item-label">白坯发货</span>
						<span class="color-list-item-title-content-item-value">{{item.roll?item.roll + '匹': ''}}{{item.meter?item.meter + '米': ''}}</span>
					</div>
					<div class="color-list-item-title-content-item">
						<span class="color-list-item-title-content-item-label">成品入库</span>
						<span class="color-list-item-title-content-item-value">{{item.prodEnterRoll?item.prodEnterRoll + '匹': ''}}{{item.prodEnterMeter?item.prodEnterMeter + '米': ''}}</span>
					</div>
					<div class="color-list-item-title-content-item">
						<span class="color-list-item-title-content-item-label">成品出货</span>
						<span class="color-list-item-title-content-item-value">{{item.prodOutRoll?item.prodOutRoll + '匹': ''}}{{item.prodOutMeter?item.prodOutMeter + '米': ''}}</span>
					</div>
				</div>
			</div>

		</div>



	</div>
</template>

<script>
import moment from 'moment'
export default {
	name: "report-item-color",
	props: {
		ColorNameData: {
			type: Object,
			default() {
				return null
			},
		},
		srcId: {
			type: Number,
			default() {
				return -1
			}
		},
		supplyId: {
			type: Number,
			default() {
				return -1
			}
		},
		version: {
			type: String,
			default() {
				return '';
			}
		}
	},
	data() {
		return {
			colorListData: [],
			backName: ' 返回',
			// ColorNameData:{},
		};
	},
	filters: {
		dealStatus(item) {
			if (item.targetJobSheetId.includes('G') || (item.targetJobSheetId.split('H').length - 1) >= 1) {
				if ((item.targetJobSheetId.split('H').length - 1) == 1 && item.targetJobSheetId[0] == 'H') {
					return '';
				}
				return '回修';
			}
			else {
				return '';
			}
		},
		getcurProcessCompleteTime(item) {
			if (item?.correspondingTime) {
				return moment(item?.correspondingTime).format("MM-DD HH:mm")
			}
			return '--'
		}
	},
	computed: {


	},
	mounted() {
		console.log(this.srcId, this.supplyId)
	},
	methods: {
		getKunCun(item, label1, label2) {
			let str = "";
			console.log(item[label1], item[label2])
			if (!item[label1] && !item[label2]) {
				return "--"
			}
			str += `${item[label1] ? item[label1] + '匹' : ''}`;
			str += `${item[label2] ? item[label2] + '米' : ''}`;
			console.log(str)
			return str;
		},
		getIconUrl(ColorNameData, type) {
			let style = {};



			return style;
		},
		showStatus(item) {
			if (item.targetJobSheetId.includes('G') || (item.targetJobSheetId.split('H').length - 1) >= 1) {
				if ((item.targetJobSheetId.split('H').length - 1) == 1 && item.targetJobSheetId[0] == 'H') {
					return false;
				}
				return true;
			}
			else {
				return false;
			}
		},
		async initColorData(version) {
			try {
				let res = await this.$Api.getShareOrderPro({
					token: this.$AuthLogin.urlps(window.location.href).data("token"),
					version: this.$AuthLogin.urlps(window.location.href).data("version"),
					id: this.ColorNameData.colorId
				});
				this.colorListData = res.data;
			} catch (error) {
				console.log(error)
			}

			// if (res.code !== 0) {
			// 	return;
			// }
			// this.colorListData = res.data;
		},
		goback() {
			this.$parent.tabChildReportColor();
		}
	},
	watch: {
		ColorNameData(newVal, oldVal) {

		},
	},

}
</script>

<style lang="less">
.main-wrap {
	width: 7.5rem;
	min-height: 100%;
	// padding-bottom: 50rem;
	background-color: #f6f6f6 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: PingFangSC-Regular, PingFang SC;
	height: fit-content;



	.back {
		text-align: left;
		width: 100%;
		padding-top: 0.10rem;
		padding-left: 0.25rem;
	}

	.color-title {
		display: flex;
		padding-top: 0.2rem;
		padding-bottom: 0.32rem;
		width: 100%;
		background-color: #ffffff;

		.color-left {
			margin-left: 0.32rem;
			width: 1.36rem;
			height: 1.36rem;
			border-radius: 0.08rem;
			margin-right: 0.30rem;
			background-repeat: no-repeat;
			background-size: 100% 100%;

			img {
				width: 100%;
			}
		}

		.color-right {
			flex: 1;
			font-size: 0.28rem;
			font-family: PingFangSC-Regular, PingFang SC;
			line-height: 0.40rem;
			color: #86909C;
			text-align: left;

			.colorRed {
				color: #F95F5F;
			}

			.color-right-item {
				margin-bottom: 0.08rem;
			}

			.color-right-name {
				font-size: 0.36rem;
				font-family: PingFangSC-Semibold, PingFang SC;

				color: #1D2129;
				line-height: 0.50rem;
			}

			.color-right-status {
				background: #F3F5F9;
				border-radius: 0.04rem;
				font-size: 0.24rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #4E5969;
				line-height: 0.40rem;
				padding: 0 0.12rem;
				margin-left: 0.16rem;
			}
		}
	}

	.color-list {
		// padding: 0 0.32rem;
		// width: 100%;
		width: 6.86rem;

	}

	.color-list-item {
		background: #FFFFFF;
		border-radius: 0.16rem;
		padding: 0.16rem 0.32rem 0.32rem;
		width: 6.86rem;
		margin-top: 0.32rem;

		.color-list-item-title {
			display: flex;
			justify-content: flex-end;

			.color-list-item-title-left {
				display: flex;
				flex: 1;

				.color-list-item-title-left-color {
					width: 0.40rem;
					height: 0.40rem;
					background-color: #8D7C5F;
					margin-right: 0.06rem;

					img {
						width: 100%;
					}
				}

				.color-list-item-title-left-title {
					font-size: 0.36rem;

					font-weight: 600;
					color: #1D2129;
					line-height: 0.50rem;

				}

				.color-list-item-title-left-status {
					font-size: 0.24rem;

					font-weight: 400;
					color: #F95F5F;
					line-height: 0.50rem;
					background: rgba(253, 203, 203, 0.2);
					border-radius: 0.04rem;
					padding: 0 0.10rem;
				}
			}

			.color-list-item-title-right {
				font-size: 0.28rem;

				font-weight: 400;
				color: #86909C;
				line-height: 0.50rem;

				.color-list-item-title-right-text {
					padding-right: 0.26rem;
				}

			}
		}

		.color-list-item-title-content {
			margin-top: 0.1rem;
			margin-left: 0.48rem;
			background-color: #f5f5f5;
			padding: 0.1rem;

			.color-list-item-title-content-item {
				display: flex;
				justify-content: space-between;
				padding-bottom: 0.24rem;

				&:last-child {
					padding-bottom: 0;
				}

				.color-list-item-title-content-item-label {
					font-size: 0.28rem;

					color: #86909C;
				}

				.color-list-item-title-content-item-value {
					flex: 1;
					text-align: right;
					font-size: 0.28rem;
					font-weight: bold;
					color: #1D2129;
				}
			}
		}
	}
}
</style>
