<template>
	<div 
		class="com_pop_mask"
		:class="[type+'_pop_mask']"
		v-show="isShow">
		<div 
			class="com_pop flex_center"
			:class="type+'_pop'" @click="closePop">
			<div 
				class="com_content"
				:class="[type+'_content']">
				<a 
					href="javascript:void(0);" 
					class="com_close_pop"
					:class="'js_close_'+type"
					@click="closePop">
				</a>
				<slot name="content"></slot>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		name: 'Pop',
		componentName: 'Pop',
		props:{
			type: String
		},
		data(){
			return{
				isShow: false
			}
		},
		mounted(){
			
		},
		methods:{
			closePop(){		
				if(this.type !== 'wx_down'){
					return;
				}	
				this.isShow = false
			},
			openPop(){
				this.isShow = true
			}
		}
	}
</script>