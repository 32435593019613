<!--
 * @Author: meng xiang cheng 3257833780@qq.com
 * @Date: 2022-02-11 15:41:16
 * @LastEditors: meng xiang cheng 3257833780@qq.com
 * @LastEditTime: 2023-03-30 10:27:49
 * @FilePath: \s-share-h5\src\views\dashboard\index.vue
 * @Description: 
-->
<template>
    <div class="dashboard_page"> 
            <p>dashboard</p>
    </div>
</template>
<script>
    export default{
        data(){
            return{

            }
        }
    }
</script>
