<template>
	<div >
		<div v-if="!isColorList" class="bdl-report-item">
			<div class="top_info">
			<div style="display: flex;justify-content: space-between;">
				<div>
					<span class="bindnumber">
						{{ item.lotno }}
					</span>
					<span class="order_status">{{ item.category  | getcategoryName }}</span>
				</div>
				<div>
					<span :class="`status status${item.status}`">{{item.status | getCardStatus}}</span>
				</div>
			</div>
			<div class="top_info-des">
				<div>
					<span class="span">{{item.companyAbbreviation}}</span>
					<div class="heng"></div>
					<span class="span">{{item.workshopName ? item.workshopName : '-'}}</span>
				</div>
				<div>
					<span class="span creator">{{item.creater}}</span>
					<div class="heng"></div>
					<span class="span">{{item.createTime | getcreateTime}}</span>
				</div>
			</div>
			<div class="supply_note" v-if="item.status == '5' && item.supplyNote !== ''">
				<span class="span">
					取消原因：<span class="spanarea">{{ item.supplyNote }}</span>
				</span>
			</div>
			</div>
			<div class="middle_info">
				<div class="color-item" v-for="(coloritem,index) in item.colorList" :key="index"
					@click.stop="tapcolorItem(coloritem)" v-if="index < len">
					<div class="color-left-span">
						<div class="rgbval icon_defualt" v-if="coloritem.rgb"
							:style="`border-radius: 0.04rem;background-color:${coloritem.rgb.indexOf('#') == 0 ? coloritem.rgb : '#' + coloritem.rgb };`">
						</div>
						<img class="icon_defualt" src="../../assets/icon_default_color.svg" v-else/>
						<span class="color-item_name">{{coloritem.colorNum}}</span>
					</div>
					<div class="color-right-span">
						<span class="span">共<span class="red">{{coloritem.vat}}</span>缸,已生产<span
								class="red">{{coloritem.progressVat ? coloritem.progressVat : 0 }}</span>缸</span>
						<img src="../../assets/arrow.svg" class="icon_arrow"/>
					</div>
				</div>
				<div class="lookmore" @click="lookMore" v-if="item.colorList.length > 5">
					<span class="span">{{isLookmore ?  '收起' : "查看更多"}}</span>
					<img src="../../assets/icon_morecolor.svg" :class="['icon_morecolor',{'more' : isLookmore}]"/>
				</div>
			</div>
		</div>
		<div  v-if="isColorList">
			<report-item-color ref="reportItemC"  :ColorNameData="ColorNameData" :srcId="srcId" :supplyId="supplyId" :version="version"></report-item-color>
		</div>
		<!-- <div class="bottom-info">
			<div class="print_btn" :style="getPrintBtnStyle" @tap.stop="print(item)">
				<image class="icon_prinit" src="@/static/icon_print.svg"></image>
				<span class="span">打印</span>
			</div>
			<div class="heng"></div>
			<div class="print_btn" :style="getOnemoreStyle" @tap.stop="oneMore(item)">
				<image class="icon_prinit" src="@/static/icon_onmore.svg" style="width: 40rem;height: 40rem;"></image>
				<span class="span">再来一单</span>
			</div>
			<div class="heng"></div>
			<div class="print_btn" :style="getmoreStyle" @tap.stop="openMore(item)">
				<image class="icon_prinit" src="@/static/icon_morecz.svg"></image>
			</div>
		</div> -->
	</div>
</template>

<script>
	// import {
	// 	FORMATDATE,
	// 	reportSuccessStatus
	// } from "@/config/constData";
	let FORMATDATE = "YYYY-MM-DD HH:mm";
	import moment from "moment";
	import reportItemColor from '../reportItemColor/index.vue'
	export default {
		name: "report-item",
		props: {
			item: {
				type: Object,
				default () {
					return {
						finishedProductInfo: "{}",
					};
				},
			},
			isNotERP: {
				type: Boolean,
				default () {
					return true;
				},
			},
			listRefresh: {
				type: Boolean,
				default () {
					return false;
				},
			},
            version: {
                type: String,
                default(){
                    return '';
                }
            }
		},
		components: {
			
			reportItemColor
		},
		data() {
			return {
				reportSuccessStatus: ["3", "4", "6", "7", ],
				isShow: false,
				isShowMore: false,
				isSwitch: false,
				bindNumber: "",
				isClick: false,
				selectData: {},
				reportItem: {
					id: undefined,
					supplyId: undefined,
					targetOrder: undefined,
					number: undefined,
				},

				colorListV2: [],
				colorListShow: false,
				colorListV2Id: -1,
				colorItemShow: false,
				showColorItemId: -2,
				colorPeriodsList: [],
				colorPeriodsListMore: [],
				lookMoreShow: false,
				len: 5,
				isLookmore: false,
				debounceEmitOnemore: null,
				isColorList: false,
				ColorNameData: {},
				srcId: -1,
				supplyId: -1,
			};
		},
		filters: {
			getcategoryName(state) {
				let categorys = ["正常订单", "试样单", "厂外回修", "厂外改染"];
				return `${categorys[state - 1]}`;
			},
			getCardStatus(status) {
				let orderStatusList = [{
					name: '全部',
					status: ""
				}, {
					name: "预报单",
					status: '1'
				}, {
					name: "待审核",
					status: "2"
				}, {
					name: "进行中",
					status: "3"
				}, {
					name: "已完成",
					status: "4"
				}, {
					name: "已取消",
					status: "5"
				}, {
					name: "取消中",
					status: "6"
				}];
				const tab = orderStatusList.filter((item) => {
					return item.status == status;
				});
				if (tab[0]) {
					return tab[0].name;
				}
				return status;
			},
			getunitString(item) {
				let arr = [];

				if (item.totalRoll && item.totalRoll !== 0) {
					const roll = `${item.totalRoll} (匹)`;
					arr.push(roll);
				}
				if (item.totalMeter && item.totalMeter !== 0) {
					arr.push(item.totalMeter + "(米)");
				}

				if (arr.length > 0) {
					return arr.join("+");
				} else {
					return "无库存";
				}
			},
			getcreateTime(item) {
				if (!item) {
					return null;
				}
				return moment(item).format("MM-DD HH:mm");
			},
		},
		computed: {
			getMount() {
				return function(rolls, meter) {
					if (rolls && meter) {
						return `${rolls}匹，${meter}米`;
					} else if (rolls && !meter) {
						return `${rolls}匹`;
					} else if (!rolls && meter) {
						return `${meter}米`;
					} else {
						return '';
					}
				}
			}
		},
		mounted() {
		},
		methods: {
			lookMore() {
				this.isLookmore = !this.isLookmore;
				if (this.isLookmore) {
					this.len = this.item.colorList.length;
				} else {
					this.len = 5;
				}
				// if(this.isLookmore)
			},
			tapcolorItem(ColorItem) {
				if (ColorItem.progressStatus == 1) {
					alert("订单暂未开始排缸生产，不支持查看。");
					return false;
				}
				let {
					lotno,
					productName,
					// supplyId
				} = this.item
				let {
					vat,
					progressVat,
					colorNum,
					rgb,
					id,
				} = ColorItem;
				let category = this.$options.filters.getcategoryName(this.item.category);
				this.ColorNameData = {
					bindNumber:lotno,
					productName,
					orderVat:vat,
					doingVat:progressVat,
					colorNum,
					rgbVal:rgb,
					category,
					colorId:id,
				}
				this.isColorList = true;
                let self = this;
				this.$nextTick(() => {
					self.$refs.reportItemC.initColorData(self.version)
					
				});

			},
			tabChildReportColor() {
				this.isColorList = false
			},
			
		},
		watch: {
			listRefresh(newVal, oldVal) {
				this.colorItemShow = false;
				this.colorListShow = false;
				this.colorListV2Id = -1;
			},
		},
	};
</script>
<style lang="less">
	.bdl-report-item {
		width: 6.86rem;
		background-color: #ffffff;
		border-radius: 0.16rem;
		margin: 0.38rem auto 0;
		box-shadow: 0 4px 16px 0px rgba(194, 194, 219, 0.24);
		padding-bottom: 0.12rem;
		.lookmore {
			text-align: center;
			padding: 0.20rem 0;

			.span {
				font-weight: 400;
				color: #3370FF;
				line-height: 0.40rem;
			}

			.icon_morecolor {
				width: 0.20rem;
				height: 0.14rem;
				margin-left: 0.10rem;

				&.more {
					transform: rotate(-180deg);
				}
			}
		}

		.bottom-info {
			height: 0.96rem;
			display: flex;
			align-items: center;

			.heng {
				width: 1px;
				height: 0.37rem;
				background-color: #F3F5F9;
			}

		}

		.middle_info {
			position: relative;

			.open_btn {
				padding: 0.15rem 0;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.3s;

				&.open {
					.btn_down {
						transform: rotateX(180deg);
					}
				}

				.btn_down {
					width: 0.28rem;
					height: 0.30rem;
				}
			}

			.color-item {
				margin: 0.24rem auto 0;
				width: 6.22rem;
				height: 0.80rem;
				padding: 0 0.16rem;
				border-radius: 0.08rem;
				background: #f7f8fa;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.color-left-span {
					display: flex;

					.icon_defualt {
						width: 0.40rem;
						height: 0.40rem;
					}

					.color-item_name {
						margin-left: 0.16rem;
						line-height: 0.44rem;
						color: #1D2129;
						font-size: 0.32rem;
						max-width: 2.80rem;
						overflow: hidden;
						white-space: nowrap;
						ext-overflow: ellipsis;
					}
				}

				.color-right-span {
					font-weight: 400;
					color: #86909C;
					line-height: 0.44rem;
					font-size: 0.28rem;
					display: flex;
					align-items: center;

					.red {
						color: #F95F5F;
					}

					.icon_arrow {
						width: 0.32rem;
						height: 0.32rem;
						margin-left: 0.16rem;
					}
				}
			}
		}

		.top_info {
			padding: 0rem 0.34rem 0rem 0.28rem;
			// height: 80rem;
			line-height: 0.80rem;

			position: relative;

			.bindnumber {
				font-size: 0.36rem;
				font-weight: 600;
				color: #1d2129;
				height: 0.50rem;
				line-height: 0.50rem;
				margin-right: 0.12rem;
			}

			.status {
				font-size: 0.28rem;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				line-height: 0.40rem;

				&.status5 {
					color: #8F9399;
				}

				&.status6 {
					color: #4E5969;
				}

				&.status7 {
					color: #FFB300;
				}

				&.status8 {
					color: #B1C600;
				}

				&.status10 {
					color: #42B430;
				}
			}

			.order_status {
				background: #f3f5f9;
				border-radius: 0.04rem;
				// border: 2rem solid #e5e6eb;
				padding: 0 0.12rem;
				height: 0.40rem;
				line-height: 0.40rem;
				font-size: 0.24rem;
				color: #4e5969;
			}

			.top_btm_info {
				position: absolute;
				right: 0.28rem;
				top: 0.16rem;
			
				background-repeat: no-repeat;
				background-size: 100% 100%;
				width: 0.40rem;
				height: 0.40rem;
			}

			.supply_note {
				
				font-size: 0.24rem;
				color: #ff0303;
				line-height: 0.34rem;
				margin-top: 0.18rem;
			}
		}

		.top_info-des {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #86909C;
			height: 0.40rem;
			line-height: 0.40rem;

			.span {
				vertical-align: middle;
                font-size: 0.28rem;
				&.creator {
					max-width: 2.00rem;
					display: inline-block;
					overflow: hidden;
					white-space: nowrap;
					span-overflow: ellipsis;
				}
			}

			.heng {
				width: 1px;
				height: 0.26rem;
				display: inline-block;
				background-color: #86909C;
				margin: 0 0.10rem;
				vertical-align: middle;
			}
		}

		.look_more {
			font-size: 0.28rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3370ff;
			text-align: center;
			padding: 0.28rem 0;

			.look_more_span {
				padding-right: 0.10rem;
			}
		}
	}
</style>
