<template>
	<div >
		<div v-if="!isProofColor" class="bdl-report-item">
			<div class="top_info">
				<div style="display: flex;justify-content: space-between;">
					<div>
						<span class="bindnumber">
							{{ item.lotno }}
						</span>
						<span class="order_status">{{item.proofCategory | getpreProofingName}}</span>
					</div>
					<div>
						<span
							:class="`status status${item.status}`">{{item.status | getCardStatus}}</span>
					</div>
				</div>
				<div class="top_info-des">
					<div>
						<span class="span">{{item.companyAbbreviation ? item.companyAbbreviation :'-'}}</span>
						<div class="heng"></div>
						<span class="span">{{item.workshopName ? item.workshopName : '-'}}</span>
					</div>
					<div>
						<span class="span creator">{{item.creater ? item.creater : '-'}}</span>
						<div class="heng"></div>
						<span class="span">{{item.createTime | getTime}}</span>
					</div>
				</div>
				<div class="supply_note" v-if="item.preProofingStatus == '5' && item.supplyNote !== ''">
					<span class="span">
						取消原因：<span class="textarea">{{ item.supplyNote }}</span>
					</span>
				</div>
			</div>
			<div class="middle_info">
				<div class="color-item" v-for="(coloritem,index) in (item.colorList)" :key="index"
					@click.stop="tapcolorItem(coloritem)" v-if="index < len">
					<div class="color-left-text">
						<div class="rgbval icon_defualt" v-if="coloritem.rgb"
							:style="`border-radius: 0.04rem;background-color:${coloritem.rgb.indexOf('#') == 0 ? coloritem.rgb : '#' + coloritem.rgb };`">
						</div>
						<img class="icon_defualt" src="../../assets/icon_default_color.svg" v-else/>
						<span class="color-item_name">{{coloritem.colorNum}}</span>
					</div>
					<div class="color-right-text">
						<span class="span">{{ coloritem.colorProgress| getColorStatusName}}</span>
						<img src="../../assets/arrow.svg" class="icon_arrow" />
					</div>
				</div>
				<!-- <div class="lookmore" @click.stop="lookMore" v-if="(item.proofingPlans).length > 5">
					<span class="span">{{isLookmore ?  '收起' : "查看更多"}}</span>
					<img src="../../assets/icon_morecolor.svg" :class="['icon_morecolor',{'more' : isLookmore}]" />
				</div> -->
			</div>
		</div>
		<div v-if="isProofColor">
			<proof-item-color ref="proofItemC" :orderInfo="item" :version="version"></proof-item-color>
		</div>
	</div>
</template>

<script>
	import proofItemColor from '../proofItemColor/index.vue'
	import moment from "moment";
	export default {
		name: "bdl-prepoofItem",
		components: {
			
			proofItemColor
		},
		props: {
			item: {
				type: Object,
				default () {
					return {
						finishedProductInfo: "{'colorList':[]}",
					}
				}
			},
			isNotERP: {
				type: Boolean,
				default () {
					return true
				}
			},
			listRefresh: {
				type: Boolean,
				default () {
					return false;
				},
			},
            version: {
                type: String,
                default(){
                    return '';
                }
            }
		},
		data() {
			return {
				isClick: false,
				reportSuccessStatus: ['6', '7', ],
				colorListShow: false,
				colorItemShow: false,
				showColorItemId: -1,
				isShowMore: false,
				isShow: false,
				colorListV2: [],
				colorListV2More: [],
				lookMoreShow: false,
				// orderInfo:{},
				// workshopShow: uni.getStorageSync("userInfo").workshop,
				len: 5,
				isLookmore: false,
				isProofColor: false,
				colorId1:-1,
				srcId1: -1,
			}
		},
		filters: {
			getCardStatus(status) {
				let orderStatusList = [{
						label: "待审核",
						value: "2"
					},
					{
						label: "打样中",
						value: "3"
					},
					{
						label: "已完成",
						value: "4"
					},
					{
						label: "已取消",
						value: "5"
					},
				];
				const tab = orderStatusList.filter((item) => {
					return item.value == status;
				});
				if (tab[0]) {
					return tab[0].label;
				}
				return status;
			},
			getColorStatusName(val) {
				switch (val) {
					case "1":
						return "报单成功";
					case "2":
						return "化验室审核通过";
					case "3":
						return "任务已分配";
					case "4":
						return "打样中";
					case "5":
						return "配方已完成";
					case "6":
						return "已取消";
					default:
						return val;
				}
			},
			getpreProofingName(val) {
				switch (val) {
					case "1":
						return "ABC样";
					case "2":
						return "自由色";
					case "3":
						return "手工样";
					case "4":
						return "做米样";
					case "5":
						return "复样开配方";
					case "6":
						return "打准开配方";
					case "7":
						return "确认样";
				}
			},
			getTime(item) {
				if (!item) {
					return '-';
				}
				return moment(item).format("MM-DD HH:mm");
			}
		},
		computed: {
			getPrintBtnStyle() {
				if (!this.isNotERP) {
					return {
						opacity: 0.3
					}
				}
				if ((this.reportSuccessStatus.indexOf(this.item.preProofingStatus) < 0)) {
					return {
						opacity: 0.3
					}
				}
			},
			getOnemoreStyle() {
				if (!this.isNotERP) {
					return {
						opacity: 0.3
					}
				}
				if (this.item.preProofingStatus !== '5') {
					return {
						opacity: 0.3
					}
				}
			},
			getmoreStyle() {
				if (this.item.preProofingStatus == '5') {
					return {
						opacity: 0.3
					}
				}
			},
		},
		methods: {
			lookMore() {
				console.log("12121")
				// this.isLookmore = !this.isLookmore;
				// if (this.isLookmore) {
				// 	this.len = (this.item.proofingPlans).length;
				// } else {
				// 	this.len = 5;
				// }
			},
			tapcolorItem(item) {
				// this.orderInfo = orderItem;
				let self = this
				this.isProofColor = true;
				this.$nextTick(() => {
					self.$refs.proofItemC.getProofingData(item.srcId, item.id,self.version)		
				});
				return;
			},
			tabChildProofColor() {
				this.isProofColor = false;
			},

		},
		watch: {
			listRefresh(newVal, oldVal) {
				this.showColorItemId = -1;
				this.colorItemShow = false;
				this.colorListShow = false;
                console.log(this.version)
			},
		},
	}
</script>
<style lang="less">
	.bdl-report-item {
		width: 6.86rem;
		// padding: 32rem 0;
		// padding: 16rem 0 14rem 0;
		background-color: #ffffff;
		border-radius: 0.16rem;
		margin-top: 0.38rem;
		margin: 0.38rem auto 0;
		box-shadow: 0px 4px 16px 0px rgba(194, 194, 219, 0.24);
		padding-bottom: 0.12rem;

		.lookmore {
			text-align: center;
			padding: 0.20rem 0;

			.span {
				font-weight: 400;
				color: #3370FF;
				line-height: 0.40rem;
			}

			.icon_morecolor {
				width: 0.20rem;
				height: 0.14rem;
				margin-left: 0.10rem;

				&.more {
					transform: rotate(-180deg);
				}
			}
		}

		.color-item {
			margin: 0.24rem auto 0;
			width: 6.22rem;
			height: 0.80rem;
			padding: 0 0.16rem;
			border-radius: 0.08rem;
			background: #f7f8fa;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.color-left-text {
				display: flex;

				.icon_defualt {
					width: 0.40rem;
					height: 0.40rem;
				}

				.color-item_name {
					margin-left: 0.16rem;
					line-height: 0.44rem;
					color: #1D2129;
					font-size: 0.32rem;
					max-width: 2.80rem;
					overflow: hidden;
					white-space: nowrap;
					ext-overflow: ellipsis;
				}
			}

			.color-right-text {
				font-weight: 400;
				color: #86909C;
				line-height: 0.44rem;
				font-size: 0.28rem;
				display: flex;
				align-items: center;

				.red {
					color: #F95F5F;
				}

				.icon_arrow {
					width: 0.32rem;
					height: 0.32rem;
					margin-left: 0.16rem;
				}
			}
		}
	

	.top_info {
		padding: 0rem 0.34rem 0rem 0.28rem;
		// height: 80rem;
		line-height: 0.80rem;

		position: relative;

		.bindnumber {
			font-size: 0.36rem;
			font-weight: 600;
			color: #1d2129;
			height: 0.50rem;
			line-height: 0.50rem;
			margin-right: 0.12rem;
		}

		.status {
			font-size: 0.28rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			line-height: 0.40rem;

			&.status5 {
				color: #8F9399;
			}

			&.status6 {
				color: #4E5969;
			}

			&.status7 {
				color: #FFB300;
			}

			&.status8 {
				color: #B1C600;
			}

			&.status10 {
				color: #42B430;
			}
		}

		.order_status {
			background: #f3f5f9;
			border-radius: 0.04rem;
			// border: 2rem solid #e5e6eb;
			padding: 0 0.12rem;
			height: 0.40rem;
			line-height: 0.40rem;
			font-size: 0.24rem;
			color: #4e5969;
		}

		.top_btm_info {
			position: absolute;
			right: 0.28rem;
			top: 0.16rem;
			//   font-size: 60rem;
			// background-image: url("@/static/more_btn.svg");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 0.40rem;
			height: 0.40rem;
		}

		.supply_note {
			// padding: 14rem 16rem;
			// background: rgba(255, 3, 3, 0.05);
			// border-radius: 8rem;
			// border: 1px solid #FF0303;
			// margin-top: 16rem;
			font-size: 0.24rem;
			color: #ff0303;
			line-height: 0.34rem;
			margin-top: 0.18rem;
		}
	}

	.top_info-des {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #86909C;
		height: 0.40rem;
		line-height: 0.40rem;

		.span {
			vertical-align: middle;

			&.creator {
				max-width: 2.00rem;
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.heng {
			width: 1px;
			height: 0.26rem;
			display: inline-block;
			background-color: #86909C;
			margin: 0 0.10rem;
			vertical-align: middle;
		}
	}

	.look_more {
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #3370ff;
		text-align: center;
		padding: 0.28rem 0;

		.look_more_text {
			padding-right: 0.10rem;
		}
	}
	}
</style>
