<!--
 * @Author: meng xiang cheng 3257833780@qq.com
 * @Date: 2023-02-09 13:36:18
 * @LastEditors: meng xiang cheng 3257833780@qq.com
 * @LastEditTime: 2023-03-16 14:48:04
 * @FilePath: \s-share-h5\src\components\proofItem\index.vue
 * @Description: 
-->
<template>
    <div class="proofItem">
        <div class="top_info">
			<span class="bindnumber">
				{{item.bindNumber}}
			</span>
			<span class="order_status">{{item.preProofingType | getpreProofingName}}</span>
		</div>
        <div class="middle_info">
			<div class="color-item" @tap.stop="onClickItem(item.id)">
				<div class="color_info">
					<div class="info_item">
						<span class="info_value">{{item.notStart }} </span>
						<span class="info_label">未开始（种）</span>
					</div>
					<div class="info_item">
						<span class="info_value">{{item.proofingCount}}</span>
						<span class="info_label">打样中（种）</span>
					</div>
					<div class="info_item">
						<span class="info_value ">{{item.doneCount }}</span>
						<span class="info_label">已完成（种）</span>
					</div>
				</div>
			</div>
			<div class="status_info"
				:class="[item.preProofingStatus==5?'cancel_status_icon':item.preProofingStatus==4?'wait_verify_status_icon':item.workshop==4?'pending_verify_status_icon':'verify_status_icon']">
			</div>
		</div>

        <div class="color_num_list">
			<span class="color_num_label">共<span class="color_num_label_num">{{item.colorCount}}</span>种颜色</span>
			<div class="color_num_label_btn" @click="showColor(item)">
				{{colorListShow?'收起':'展开'}}
                <img src="../../assets/icon_arrow.svg" :class="{ 'u-arrow-down-icon-active': colorListShow }" class="color_num_label_icon" alt="arrow-down-fill">
			</div>
		</div>
        <div v-show="colorListShow" class="color_num_list_table">

            <div v-for="(colorItem, index) in colorListV2" :item="colorItem" :key="index+ '1'">
                <div class="color_num_list_body_item">
                    <div class="color_num_list_body_td">
                        <span class="color_num_list_body_label">{{ colorItem.colorNum }}</span>
                        <span class="color_num_list_body_value"
                            :class="[colorItem.colorStatus==2?'colorStatusFinsh':colorItem.colorStatus==1?'colorStatusPending':'']">
                            {{colorItem.colorStatus==0? '未开始':colorItem.colorStatus==1?'打样中':'已完成'}}
                        </span>
                    </div>
                    <div class="color_num_list_body_td">
                        <span class="color_num_list_body_label">{{colorItem.colorStatus==0? '预计开始':'实际开始'}}</span>
                        <span class="color_num_list_body_value" v-if="colorItem.colorStatus==0">{{ colorItem.preStartTime|getTime }}</span>
                        <span class="color_num_list_body_value" v-else>{{ colorItem.realStartTime|getTime }}</span>
                    </div>
                    <div class="color_num_list_body_td">
                        <span class="color_num_list_body_label">{{colorItem.colorStatus==0? '预计完成':colorItem.colorStatus==1?'预计完成':'实际完成'}}</span>
                        <span class="color_num_list_body_value"
                            v-if="colorItem.colorStatus==1||colorItem.colorStatus==0">{{ colorItem.preEndTime|getTime }}</span>
                         <span class="color_num_list_body_value" v-else>{{ colorItem.realEndTime|getTime }}</span>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default{
        name:"proofItem",
        data(){
            return{
                colorListShow:true,
                colorItemShow:false,
                showColorItemId:-2,
                colorListV2:[]
            }
        },
        filters:{
            getpreProofingName(val) {
				switch (val) {
					case 1:
						return "ABC样";
					case 2:
						return "自由色";
					case 3:
						return "手工样";
					case 4:
						return "做米样";
					case 5:
						return "复样开配方";
					case 6:
						return "打准开配方";
					case 7:
						return "确认样";
				}
			},
            getTime(item){
                if (!item) {
					return '-';
				}
				return moment(item).format("MM-DD HH:mm");
            }
        },
        props:{
            item:{
                type:Object,
                default(){
                    return{

                    }
                }
            },
            shareToken:{
                type:String,
                default:""
            },
            version: {
                type:String,
                default:""
            }
        },
        watch:{
            colorListShow:{
                deep:true,
                handler(newvalue,oldval){
                    if(!!newvalue){
                        this.getPreproofPlan();
                    }
                }
            }
        },
        mounted(){
            this.getPreproofPlan();
        },
        methods:{
            onClickItem(){
            },
            showColor(item){
                this.colorListShow = !this.colorListShow;
            },
            async getPreproofPlan(){
                let obj = {
                    srcId: this.item.id,
                    shareToken:this.shareToken,
                    pageSize: 200,
                }
                let res = await this.$Api.getPreproofPlan(obj,this.version);
                if(res.code !== 0) return;
                this.colorListV2 = res.data;
            }
        }
    }
</script>